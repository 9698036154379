<template>
    <div id="js-loader" class="loader">
      <div class="loader-animation"></div>
    </div>
    <div class="page_header">
      <div class="mv_cloud">
        <div class="left_area">
          <div class="img01">
            <img
              src="@/assets/front_component/images/assets/back01.png"
              alt="雲"
            />
          </div>
        </div>
        <div class="right_area">
          <div class="img02">
            <img
              src="@/assets/front_component/images/assets/back02.png"
              alt="雲"
            />
          </div>
        </div>
      </div>
      <div class="en_ttl en"><span>APPROVE/REJECT</span></div>
      <div class="content_wrap">
        <h2>承認/却下コメント</h2>
        <div class="mascot_wrap">
          <!-- <img
            src="@/assets/front_component/images/assets/meguru05.png"
            alt="めぐる君"
          /> -->
          <div v-if="phone"> 
            <div
            class="user_img"
            style="bottom: -20px;"
            :style="{ backgroundImage: 'url(' + profileImg + ')' }"
            v-if="profileImg"
          ></div>
          <div
            class="user_img"
            style="bottom: -20px;"
            :style="{
              backgroundImage:
                'url(' +
                require('@/assets/front_component/images/test/test01.png') +
                ')',
            }"
            v-else
          ></div>
          </div>
          <div v-else> 
          <div
            class="user_img"
            style="bottom: 50px;"
            :style="{ backgroundImage: 'url(' + profileImg + ')' }"
            v-if="profileImg"
          ></div>
          <div
            class="user_img"
            style="bottom: 50px;"
            :style="{
              backgroundImage:
                'url(' +
                require('@/assets/front_component/images/test/test01.png') +
                ')',
            }"
            v-else
          ></div>
          </div>
        </div>
      </div>
    </div>
    <main>
      <div class="section_all_wrap">
        <div class="content_wrap">
          <div class="tab_wrap_reject" v-if="phone">
            <ul class="tab_list" style="justify-content: center;">
              <li class="kankyo active">
                <div class="icon_wrap" id="receive-message" @click="sendMessage">
                  <img
                    src="@/assets/front_component/images/assets/staff06_out.png"
                    alt="届いたコメント"
                    class="mouse_out"
                  />
                  <img
                    src="@/assets/front_component/images/assets/staff06_over.png"
                    alt="届いたコメント"
                    class="mouse_over"
                  />
                  <img
                    src="@/assets/front_component/images/assets/staff06_active.png"
                    alt="届いたコメント"
                    class="mouse_active"
                  />
                </div>
                <h3>届いたコメント</h3>
              </li>
              <li class="eigyo">
                <div class="icon_wrap" id="send-message" @click="receivedMessage">
                  <img
                    src="@/assets/front_component/images/assets/staff03_out.png"
                    alt="送ったコメント"
                    class="mouse_out"
                  />
                  <img
                    src="@/assets/front_component/images/assets/staff03_over.png"
                    alt="送ったコメント"
                    class="mouse_over"
                  />
                  <img
                    src="@/assets/front_component/images/assets/staff03_active.png"
                    alt="送ったコメント"
                    class="mouse_active"
                  />
                </div>
                <h3>送ったコメント</h3>
              </li>
            </ul>
          </div>
          <div class="tab_wrap_reject" v-else>
            <ul class="tab_list">
              <li class="kankyo active">
                <div class="icon_wrap" id="receive-message" @click="sendMessage">
                  <img
                    src="@/assets/front_component/images/assets/staff06_out.png"
                    alt="届いたコメント"
                    class="mouse_out"
                  />
                  <img
                    src="@/assets/front_component/images/assets/staff06_over.png"
                    alt="届いたコメント"
                    class="mouse_over"
                  />
                  <img
                    src="@/assets/front_component/images/assets/staff06_active.png"
                    alt="届いたコメント"
                    class="mouse_active"
                  />
                </div>
                <h3>届いたコメント</h3>
              </li>
              <li class="eigyo">
                <div class="icon_wrap" id="send-message" @click="receivedMessage">
                  <img
                    src="@/assets/front_component/images/assets/staff03_out.png"
                    alt="送ったコメント"
                    class="mouse_out"
                  />
                  <img
                    src="@/assets/front_component/images/assets/staff03_over.png"
                    alt="送ったコメント"
                    class="mouse_over"
                  />
                  <img
                    src="@/assets/front_component/images/assets/staff03_active.png"
                    alt="送ったコメント"
                    class="mouse_active"
                  />
                </div>
                <h3>送ったコメント</h3>
              </li>
            </ul>
          </div>
  
        <div class="content_in_wrap">
          <div id="breadcrumb">
            <ul class="breadcrumb_list">
              <li>
                <router-link :to="{ name: 'Front top' }">ホーム</router-link>
              </li>
              <li>
                  <router-link :to="{ name: 'Front user edit' }">ユーザープロフィール</router-link>
              </li>
              <li><span>承認/却下コメント一覧</span></li>
            </ul>
          </div>
          <div class="content" v-if="phone">
            <div class="content_in_wrap">
              <div class="innar">
                <div class="js-scrollable">
                    <table class="tbl_list">
                      <tr class="sort">
                        <th class="tbl_list_name t_c">
                          <div class="sort_ttl">
                            <span>
                              種別
                            </span>
                          </div> 
                        </th>
                        <th class="tbl_list_name t_c">
                          <div class="sort_ttl">
                            <span>
                              タイトル
                            </span>
                          </div> 
                        </th>
                        <th class="tbl_list_name t_c">
                          <div class="sort_ttl" v-if="this.send_message == true">                        
                            <span>差出人</span>
                          </div> 
                          <div class="sort_ttl" v-if="this.receive_message == true">  
                            <span>宛先</span>
                          </div>   
                        </th>
                        <th class="tbl_list_name t_c">
                          <div class="sort_ttl">
                          <span>
                            投稿時間
                          </span>
                        </div> 
                        </th>
                        <th class="tbl_list_name t_c">
                          <div class="sort_ttl">
                            <span>
                              承認結果
                            </span>
                          </div> 
                        </th>
                      </tr>
                      <tr v-for="message in messages.slice()" :key="message.id">      
                        <td class="tbl_list_name">
                            <div v-if="message.type == message_type.idea">
                              創発
                            </div>
                            <div v-if="message.type == message_type.sdgs">
                              SDGs個人目標
                            </div>
                            <div v-if="message.type == message_type.challenge">
                              チャレンジシート
                            </div>
                            <div v-if="message.type == message_type.news">
                              お知らせ
                            </div>
                            <div v-if="message.type == message_type.column">
                              コラム
                            </div>
                            <div v-if="message.type == message_type.photolog">
                              フォトログ
                            </div>                      
                          </td>
                          <td class="tbl_list_ttl">
                              <router-link
                                v-if="
                                  message.type == message_type.challenge || message.type == message_type.sdgs
                                "
                                :to="{
                                  name: 'Control messageshow',
                                  params: { type: message.type, id: message.id },
                                }"
                              >
                                {{ showYear(message.option) }}
                              </router-link>
                              <router-link
                                v-else
                                :to="{
                                  name: 'Control messageshow',
                                  params: { type: message.type, id: message.id },
                                }"
                                ><span v-html="message.content"></span></router-link
                              >
                          </td> 
                          <td class="tbl_list_name t_c">
                            <div v-if="this.receive_message == true">   
                              <div>
                                {{ message.from_user ? message.from_user.last_name : '' }}
                                {{ message.from_user ? message.from_user.first_name : '' }}
                              </div>                               
                            </div> 
                            <div v-if="this.send_message == true"> 
                            {{ message.target_last_name}} {{message.target_first_name}}
                            </div>                      
                          </td>             
                          <td class="tbl_list_name t_c">
                            <span class="day_time">
                              <img
                                src="@/assets/front_component/images/assets/board01.png"
                                alt="時間"
                              /><time
                                class="en"
                                :datetime="formatDate_time(message.created_at)"
                                >{{ formatDate(message.created_at) }}</time
                              >
                            </span>
                          </td>
                          <td class="tbl_list_name t_c">
                            <div v-if="message.status == '承認済'">承認</div>
                            <div v-if="message.status == '却下'">却下</div>
                          </td>  
                        <!-- <template v-if="message">
                          <td class="tbl_list_name t_c">
                              <div class="st_popup">
                                  <router-link
                                    :to="{
                                      name: 'Control messageshow',
                                      params: { type: message.type, id: message.id },
                                    }"
                                    >コメントをみる</router-link
                                  >
                                  <span class="reject-message">     
                                  </span>
                                   <div class="submit_wrap click_pop">
                                    <div
                                      style="display: none"
                                      enctype="multipart/form-data"
                                      autocomplete="off"
                                      class="click_popup"
                                    >
                                      <div class="innar">
                                        <div class="form_outwrap">
                                          <div class="wrap wid_100per">
                                          <div v-if="message.status == '承認済'">
                                            <p class="ttl_03">
                                              コメント
                                                <i
                                                class="fas fa-times close-icon"
                                                @click="
                                                  closeRejectMessagePopup()
                                                  "
                                              ></i>
                                            </p>
                                          </div>
                                          <div v-if="message.status == '却下'">
                                            <p class="ttl_03">
                                                理由
                                                <i
                                                class="fas fa-times close-icon"
                                                @click="
                                                  closeRejectMessagePopup()
                                                  "
                                              ></i>
                                            </p>
                                          </div>
                                            <div class="in_wrap input_wrap">
                                              <span class="st_comment">{{
                                                approval_message
                                              }}</span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div> 
                              </div>
                          </td>  
                        </template> -->
                      </tr>
                    </table>
                    <div id="load" style="display: none" class="spinner-style">
                      <div class="half-circle-spinner">
                        <div class="circle circle-1"></div>
                        <div class="circle circle-2"></div>
                      </div>
                    </div>
                    <div
                      id="more_btn"
                      class="t_c"
                      v-if="add_post_num < total && get_post_num < total"
                    >
                    <div v-if="this.receive_message == true" class="btn_wide btn_style">
                      <button v-on:click="receivedMessageListmore">さらに読み込む</button>
                    </div>
                    <div v-if="this.send_message == true" class="btn_wide btn_style">
                      <button v-on:click="sendMessageListMore">さらに読み込む</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="content" v-else>
            <div class="content_in_wrap">
              <div class="innar">
                <div class="js-scrollable">
                    <table class="tbl_list">
                      <tr class="sort">
                        <th class="tbl_list_name t_c">
                          <div class="sort_ttl">
                            <span>
                              種別
                            </span>
                          </div> 
                        </th>
                        <th class="tbl_list_ttl t_c">
                          <div class="sort_ttl">
                            <span>
                              タイトル
                            </span>
                          </div> 
                        </th>
                        <th class="tbl_list_ttl t_c">
                          <div class="sort_ttl" v-if="this.send_message == true">                        
                            <span>差出人</span>
                          </div> 
                          <div class="sort_ttl" v-if="this.receive_message == true">  
                            <span>宛先</span>
                          </div>   
                        </th>
                        <th class="tbl_list_ttl t_c">
                          <div class="sort_ttl">
                          <span>
                            投稿時間
                          </span>
                        </div> 
                        </th>
                        <th class="tbl_list_name t_c">
                          <div class="sort_ttl">
                            <span>
                              承認結果
                            </span>
                          </div> 
                        </th>
                        <!-- <th class="tbl_list_name t_c">
                          <div class="sort_ttl">
                            <span>
                              操作 （ボタン）
                            </span>
                          </div> 
                        </th> -->
                      </tr>
                      <tr v-for="message in messages.slice()" :key="message.id">      
                        <td class="tbl_list_name">
                            <div v-if="message.type == message_type.idea">
                              創発
                            </div>
                            <div v-if="message.type == message_type.sdgs">
                              SDGs個人目標
                            </div>
                            <div v-if="message.type == message_type.challenge">
                              チャレンジシート
                            </div>
                            <div v-if="message.type == message_type.news">
                              お知らせ
                            </div>
                            <div v-if="message.type == message_type.column">
                              コラム
                            </div>
                            <div v-if="message.type == message_type.photolog">
                              フォトログ
                            </div>                      
                          </td>
                          <td class="tbl_list_ttl">
                              <router-link
                                v-if="
                                  message.type == message_type.challenge || message.type == message_type.sdgs
                                "
                                :to="{
                                  name: 'Control messageshow',
                                  params: { type: message.type, id: message.id },
                                }"
                              >
                                {{ showYear(message.option) }}
                              </router-link>
                              <router-link
                                v-else
                                :to="{
                                  name: 'Control messageshow',
                                  params: { type: message.type, id: message.id },
                                }"
                                ><span v-html="message.content"></span></router-link
                              >
                          </td> 
                          <td class="tbl_list_name t_c">
                            <div v-if="this.receive_message == true">   
                              <div>
                                {{ message.from_user ? message.from_user.last_name : '' }}
                                {{ message.from_user ? message.from_user.first_name : '' }}
                              </div>                               
                            </div> 
                            <div v-if="this.send_message == true"> 
                            {{ message.target_last_name}} {{message.target_first_name}}
                            </div>                      
                          </td>             
                          <td class="tbl_list_name t_c">
                            <span class="day_time">
                              <img
                                src="@/assets/front_component/images/assets/board01.png"
                                alt="時間"
                              /><time
                                class="en"
                                :datetime="formatDate_time(message.created_at)"
                                >{{ formatDate(message.created_at) }}</time
                              >
                            </span>
                          </td>
                          <td class="tbl_list_name t_c">
                            <div v-if="message.status == '承認済'">承認</div>
                            <div v-if="message.status == '却下'">却下</div>
                          </td>  
                        <!-- <template v-if="message">
                          <td class="tbl_list_name t_c">
                              <div class="st_popup">
                                  <router-link
                                    :to="{
                                      name: 'Control messageshow',
                                      params: { type: message.type, id: message.id },
                                    }"
                                    >コメントをみる</router-link
                                  >
                                  <span class="reject-message">     
                                  </span>
                                    <div class="submit_wrap click_pop">
                                    <div
                                      style="display: none"
                                      enctype="multipart/form-data"
                                      autocomplete="off"
                                      class="click_popup"
                                    >
                                      <div class="innar">
                                        <div class="form_outwrap">
                                          <div class="wrap wid_100per">
                                          <div v-if="message.status == '承認済'">
                                            <p class="ttl_03">
                                              コメント
                                                <i
                                                class="fas fa-times close-icon"
                                                @click="
                                                  closeRejectMessagePopup()
                                                  "
                                              ></i>
                                            </p>
                                          </div>
                                          <div v-if="message.status == '却下'">
                                            <p class="ttl_03">
                                                理由
                                                <i
                                                class="fas fa-times close-icon"
                                                @click="
                                                  closeRejectMessagePopup()
                                                  "
                                              ></i>
                                            </p>
                                          </div>
                                            <div class="in_wrap input_wrap">
                                              <span class="st_comment">{{
                                                approval_message
                                              }}</span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div> 
                              </div>
                          </td>  
                        </template> -->
                      </tr>
                    </table>
                    <div id="load" style="display: none" class="spinner-style">
                      <div class="half-circle-spinner">
                        <div class="circle circle-1"></div>
                        <div class="circle circle-2"></div>
                      </div>
                    </div>
                    <div
                      id="more_btn"
                      class="t_c"
                      v-if="add_post_num < total && get_post_num < total"
                    >
                    <div v-if="this.receive_message == true" class="btn_wide btn_style">
                      <button v-on:click="receivedMessageListmore">さらに読み込む</button>
                    </div>
                    <div v-if="this.send_message == true" class="btn_wide btn_style">
                      <button v-on:click="sendMessageListMore">さらに読み込む</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

        <div class="kusa_outline">
          <div class="kusa_area">
            <div class="deco_wrap01">
              <img
                src="@/assets/front_component/images/assets/kusa01.png"
                alt="草"
              />
            </div>
            <div class="deco_wrap02">
              <img
                src="@/assets/front_component/images/assets/kusa02.png"
                alt="草"
              />
            </div>
            <div class="deco_wrap03">
              <img
                src="@/assets/front_component/images/assets/kusa04.png"
                alt="草"
              />
            </div>
            <div class="deco_wrap04">
              <img
                src="@/assets/front_component/images/assets/kusa03.png"
                alt="草"
              />
            </div>
            <div class="deco_wrap05">
              <img
                src="@/assets/front_component/images/assets/kusa04.png"
                alt="草"
              />
            </div>
            <div class="deco_wrap06">
              <img
                src="@/assets/front_component/images/assets/kusa03.png"
                alt="草"
              />
            </div>
            <div class="deco_wrap07">
              <img
                src="@/assets/front_component/images/assets/kusa05.png"
                alt="草"
              />
            </div>
            <div class="deco_wrap08">
              <img
                src="@/assets/front_component/images/assets/kusa04.png"
                alt="草"
              />
            </div>
            <div class="deco_wrap09">
              <img
                src="@/assets/front_component/images/assets/kusa01.png"
                alt="草"
              />
            </div>
            <div class="deco_wrap10">
              <img
                src="@/assets/front_component/images/assets/kusa07.png"
                alt="草"
              />
            </div>
            <div class="deco_wrap11">
              <img
                src="@/assets/front_component/images/assets/kusa06.png"
                alt="草"
              />
            </div>
            <div class="deco_wrap12">
              <img
                src="@/assets/front_component/images/assets/kusa01.png"
                alt="草"
              />
            </div>
            <div class="deco_wrap13">
              <img
                src="@/assets/front_component/images/assets/kusa04.png"
                alt="草"
              />
            </div>
            <div class="deco_wrap14">
              <img
                src="@/assets/front_component/images/assets/kusa03.png"
                alt="草"
              />
            </div>
            <div class="deco_wrap15">
              <img
                src="@/assets/front_component/images/assets/kusa04.png"
                alt="草"
              />
            </div>
            <div class="deco_wrap16">
              <img
                src="@/assets/front_component/images/assets/kusa03.png"
                alt="草"
              />
            </div>
            <div class="deco_wrap17">
              <img
                src="@/assets/front_component/images/assets/kusa01.png"
                alt="草"
              />
            </div>
          </div>
        </div> 
      </div>
    </main>
    <div class="menu_wrap">
      <div class="menu_icon">
        <img
          src="@/assets/front_component/images/assets/menu01.png"
          alt="メニュー"
        />
      </div>
      <div class="menu_list_wrap">
        <div class="menu_ttl">作成する</div>
        <ul class="menu_list">
          <li>
            <router-link
              :to="{
                name: 'Control messagecreate',
                params: { type: 'mysdgs' },
              }"
              >SDGsレポート</router-link
            >
          </li>
          <!-- <li>
            <router-link
              :to="{ name: 'Control messagecreate', params: { type: 'news' } }"
              >お知らせ</router-link
            >
          </li> -->
          <li>
            <router-link
              :to="{ name: 'Control messagecreate', params: { type: 'thanks' } }"
              >サンクス&ホメロンカード</router-link
            >
          </li>
          <li>
            <router-link
              :to="{ name: 'Control messagecreate', params: { type: 'idea' } }"
              >創発カード</router-link
            >
          </li>
          <li>
            <router-link
              :to="{ name: 'Control messagecreate', params: { type: 'daily' } }"
              >気づき日報</router-link
            >
          </li>
          <li>
            <router-link
              :to="{
                name: 'Control messagecreate',
                params: { type: 'photolog' },
              }"
              >フォトログ</router-link
            >
          </li>
          <li>
            <router-link
              :to="{ name: 'Control messagecreate', params: { type: 'column' } }"
              >コラム</router-link
            >
          </li>
        </ul>
      </div>
    </div>
  </template>
  
  <script>
  import Api from "@/apis/Api";
  import message from "@/apis/Message";
  import dayjs from "dayjs";
  import MessageType from "@/const/MessageType.json";
  import User from "@/apis/User";
  import Groups from "@/apis/Groups";
  import $ from "jquery";
  import striptags from "striptags";
  import ScrollHint from "scroll-hint";
  import "@/assets/front_component/js/functions.js";
  
  export default {
    name: "reject",
    data() {
      return {
      phone: "",
      profileImg: false,
      messages: [],
      get_post_num: 25,
      add_post_num: 25,
      messages_send: [],
      total: "",
      filteredDatas: [],
      loader: false,
      more_load: false,
      message_target_users: {},
      send_message: false,
      receive_message: false,
      approval_message: null,
      target_user: [],
      MessageType() {
        return MessageType;
      },
      message_type: {idea:"idea", sdgs:"sdgs", challenge:"challenge", news:"news", column:"column", photolog:"photolog"},
      order: "desc",
      status: "all",
      first_click: true,
      };
    },
    components: {
    },
    watch: {},

    beforeCreate() {
      $(function () {
      $("#js-loader").show();
      $("#load").show();
      $(window).scrollTop(0);
    });
    },

    created() {
    this.profileImgApi();
    this.sendMessage();
    this.phoneMatch();

    },
    methods: {
        phoneMatch: function () {
        var ua = navigator.userAgent;
        if (
          ua.indexOf("iPhone") > 0 ||
          (ua.indexOf("Android") > 0 && ua.indexOf("Mobile") > 0)
        ) {
          this.phone = true;
          document.body.id = "sp_style";
        } else {
          this.phone = false;
          document.body.id = "pc_style";
        }
      },
      profileImgApi() {
      Api()
        .get("api/v1/files/users/" + this.$User.id + "?where=key@users")
        .then(
          (response) => {
            if (response != "") {
              let path = response.data.data[0].storage_file_name;
              let fileApiPass = "/api/v1/file/" + path;
              Api()
                .get(fileApiPass, { responseType: "arraybuffer" })
                .then(
                  (response) => {
                    let blob = new Blob([response.data], {
                      type: response.headers["content-type"],
                    });
                    let url = window.URL || window.webkitURL;
                    let src = url.createObjectURL(blob);
                    this.profileImg = src;
                  },
                  (error) => {}
                );
            }
          },
          (error) => {
            console.log(error);
          }
        )
        .catch(() => {});
    },
    receivedMessage() {
       $("#load").show();

        this.loader = true;
        this.first_click = true;
        this.messages = [];
        this.receive_message = true;
        this.send_message = false;
        let type = "challenge,idea,sdgs,news,column,photolog";
        let num  = this.get_post_num;
        let id = this.$User.id;
        let order = this.order;
        let status = this.status;

      message
      // .approval_list(type, num, id, order, status)
        .approval_received(type, num, id, order, status)
        .then((response) => {
          if (response != null) {

            if (response.data.data.length === 0) {
              this.table_loader = false;
            }
            this.messages = response.data.data;
            this.messages.forEach((message) => {
              if (message.type == this.message_type.sdgs || message.type == this.message_type.challenge) {
                
                if (this.IsJsonString(message.content)) {
                  let contentSplit = JSON.parse(message.content);

                  message.content = striptags(contentSplit.content);

                  message.content_1 = striptags(contentSplit.content_1);

                  message.content_2 = striptags(contentSplit.content_2);

                  message.option = contentSplit.option;
                } else {
                  message.option = striptags(message.content);
                }
              } else {
                message.content = striptags(message.content);
              }

            });

            this.total = response.data.total;
          }
        })
        .catch((error) => {
          console.log("エラー");
          console.log(error);
        })
        .finally(() => {
          $(".click_popup").css("display", "none");
          $("#load").hide();
          this.table_loader = false;
          this.loader = false;
        });
    },
    receivedMessageListmore() {
      $("#more_btn").hide();
      $("#load").show();

        this.get_post_num = this.get_post_num + this.add_post_num;
        this.loader = true;
        this.receive_message = true;
        this.send_message = false;
        let type = "challenge,idea,sdgs,news,column,photolog";
        let num  = this.get_post_num;
        let id = this.$User.id;
        let order = this.order;
        let status = this.status;
        this.first_click = true;

      message
        .approval_received(type, num, id, order, status)
        .then((response) => {
          this.more_load = true;
          if (response != null) {
            if (response.data.data.length === 0) {
              this.table_loader = false;
            }
    
            this.messages = response.data.data
            this.messages.forEach((message) => {
              if (message.type == this.message_type.sdgs || message.type == this.message_type.challenge) {
                
                if (this.IsJsonString(message.content)) {
                  let contentSplit = JSON.parse(message.content);

                  message.content = striptags(contentSplit.content);

                  message.content_1 = striptags(contentSplit.content_1);

                  message.content_2 = striptags(contentSplit.content_2);

                  message.option = contentSplit.option;
                } else {
                  message.option = striptags(message.content);
                }
              } else {
                message.content = striptags(message.content);
              }

            });
            this.total = response.data.total;
          }
 
        })
        .catch((error) => {
          console.log("エラー");
          console.log(error);
        })
        .finally(() => {
          this.loader = false;
        });
    },
    sendMessage() {
      this.messages = [];
      this.loader = true;
      this.receive_message = false;
      this.send_message =true;
      this.first_click = true;

        let type = "challenge,idea,sdgs,news,column,photolog";
        let num  = this.get_post_num;
        let id = this.$User.id;
        let order = this.order;
        let status = this.status;

        message
        .approval_send(type, num, id, ["承認済,却下"])
        .then((response) => {
          if (response != null) {
            
            this.send_message = true;
            if (response.data.data.length === 0) {
              this.table_loader = false;
            }

            this.messages = response.data.data;
            
            this.messages.forEach((message_single) => {
              User
              .show(message_single.act_users[0].user_id)
              .then((response) => {
                message_single.target_first_name = response.data.data.first_name
                message_single.target_last_name = response.data.data.last_name
              }) 
              if (message_single.type == "sdgs" || message_single.type == "challenge") {
                
                if (this.IsJsonString(message_single.content)) {
                  let contentSplit = JSON.parse(message_single.content);

                  message_single.content = striptags(contentSplit.content);

                  message_single.content_1 = striptags(contentSplit.content_1);

                  message_single.content_2 = striptags(contentSplit.content_2);

                  message_single.option = contentSplit.option;
                } else {
                  message_single.option = striptags(message_single.content);
                }
              } else {
                message_single.content = striptags(message_single.content);
              }        
            });

            this.total = response.data.total;
          }
        })
        .catch((error) => {
          console.log("エラー");
          console.log(error);
        })
        .finally(() => {
          $(".click_popup").css("display", "none");
          $("#load").hide();
          this.table_loader = false;
          this.loader = false;
        });
    },
    sendMessageListMore() {
      $("#more_btn").hide();
      $("#load").show();

      this.get_post_num = this.get_post_num + this.add_post_num;
      this.first_click = true;
      this.loader = true;
      this.receive_message = false;
      this.send_message =true;

        let type = "challenge,idea,sdgs,news,column,photolog";
        let num  = this.get_post_num;
        let id = this.$User.id;
        let order = this.order;
        let status = this.status;

        message
        .approval_send(type, num, id, ["承認済,却下"])
        .then((response) => {
          this.more_load = true;

          if (response != null) {
            
            this.send_message = true;
            if (response.data.data.length === 0) {
              this.table_loader = false;
            }

            this.messages = response.data.data;
            
            this.messages.forEach((message_single) => {
              User
              .show(message_single.act_users[0].user_id)
              .then((response) => {
                message_single.target_first_name = response.data.data.first_name
                message_single.target_last_name = response.data.data.last_name
              })
              if (message_single.type == "sdgs" || message_single.type == "challenge") {
                
                if (this.IsJsonString(message_single.content)) {
                  let contentSplit = JSON.parse(message_single.content);

                  message_single.content = striptags(contentSplit.content);

                  message_single.content_1 = striptags(contentSplit.content_1);

                  message_single.content_2 = striptags(contentSplit.content_2);

                  message_single.option = contentSplit.option;
                } else {
                  message_single.option = striptags(message_single.content);
                }
              } else {
                message_single.content = striptags(message_single.content);
              }
            });

            this.total = response.data.total;
          }
        })
        .catch((error) => {
          console.log("エラー");
          console.log(error);
        })
        .finally(() => {
        this.loader = false;
        });
    },
    kyakka_modal(messageid) {

      this.approval_message = null;
      if (this.first_click == true) {
        $(".st_popup").each(function () {
            $(this).click(function (e) {
            $(".click_popup").css("display", "none");
            $(this).find(".click_popup").css("display", "block");
              });
          });
      this.first_click = false;
      }

      message
      .listChild(messageid, "approval_message")
      .then((response) => {
          if (response != null) {
            if(response.data.data[0].content == 'no content') {
              this.approval_message = ''
            }
            else {
              this.approval_message = response.data.data[0].content
            }
            $(".st_popup").each(function () {
              $(this).click(function (e) {
                $(".click_popup").css("display", "none");
                $(this).find(".click_popup").css("display", "block");
                });
          });
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loader = false;
        });
    },
    closeRejectMessagePopup() {
      $(".st_popup").each(function () {
        $(this).click(function (e) {
        $(".click_popup").css("display", "none");
        $(this).find(".click_popup").css("display", "none");
          });
      });
    },
    
    IsJsonString(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    },
    showYear(year) {
      if (typeof year == "number") {
        year = year.toString();
      }

      let setYear = "";
      switch (year) {
        case "2022":
          setYear = "49期 (2022)";
          break;
        case "2023":
          setYear = "50期 (2023)";
          break;
        case "2024":
          setYear = "51期 (2024)";
          break;
        case "2025":
          setYear = "52期 (2025)";
          break;
        case "2026":
          setYear = "53期 (2026)";
          break;
        default:
          setYear = year;
          break;
      }
      return setYear;
    },
    formatDate: (dateStr) => dayjs(dateStr).format("YYYY.MM.DD hh:mm"),
    formatDate_time: (dateStr) => dayjs(dateStr).format("YYYY-MM-DD"),
    },
    mounted() {

      document.body.className = "page_staff";
      
      this.$nextTick(function () {
        $(function () {
          $(".menu_icon").click(function () {
            if ($(".menu_list_wrap").hasClass("show")) {
              $(".menu_list_wrap").removeClass("show");
            } else {
              $(".menu_list_wrap").addClass("show");
            }
          });
        });
                 
        $(document).click(function (event) {
          if (!$(event.target).closest(".menu_wrap").length) {
            $(".menu_list_wrap").removeClass("show");
          }
        });
        $(function () {
          var content_height = $("#app").height(); // コンテンツの高さを取得
          var pagetop_show = content_height - 1500; // ページトップを出すの高さを設定
          $(window).on("scroll", function () {
            var scroll = $(window).scrollTop();
            var windowHeight = $(window).height();
            //ページトップ表示
            if (scroll > pagetop_show && scroll > 60) {
              $(".pagetop_wrap").addClass("show");
            } else {
              $(".pagetop_wrap").removeClass("show");
            }
          });
        });
        new ScrollHint(".js-scrollable", {
          suggestiveShadow: true,
          i18n: {
            scrollable: "スクロールできます",
          },
        });
        $(function () {
          $("#js-loader").delay(300).fadeOut(600);
          $(window).scrollTop(0);
        });
      });
    },
    updated() {
      if (this.more_load == true) {
          $("#load").hide();
          $("#more_btn").show();
          this.more_load = false;
        }
    },
  };
  </script>
  
  <style scoped>
  .hieght-45 {
    height: 45px;
  }
  
  /* 外部CSSスタイルシートを追加する */
      .reject-message {
          cursor: pointer;
        }
        .reject-message:after {
          content: ""; /* This is necessary for the pseudo element to work. */
           display: block;/* This will put the pseudo element on its own line. */
          margin: 0 auto; /* This will center the border. */
          width: 32%; /* Change this to whatever width you want. */
          padding-top: 7px; /* This creates some space between the element and the border. */
          /* border-bottom: 3px solid orange; This creates the border. Replace black with whatever color you want. */
        }
        .close-icon {
          position: absolute;
          right: 20px;
          top: 12px;
          cursor: pointer;
        }
        .btn_style {
          margin-top: 30px;
        }
        .spinner-style {
          margin-top: 30px;
        }
  </style>
  